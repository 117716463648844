<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="
          col-7
          p-0
          align-self-center
          font-weight-bold
          d-flex
          align-items-center
          w-reset
          mb-sm-2
        "
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.patientResidenceReport") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
          <el-date-picker
            size="mini"
            v-model="filterForm.start_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.start_date')"
          ></el-date-picker>
          <el-date-picker
            size="mini"
            v-model="filterForm.end_date"
            type="date"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
            :placeholder="$t('message.end_date')"
          ></el-date-picker>
           <div class="px-2">
            <select-city
              size="mini"
              v-model="filterForm.city_id"
              :id="filterForm.city_id"
            ></select-city>
          </div>
          <select-region
            size="mini"
            v-model="filterForm.region_id"
            :id="filterForm.region_id"
          ></select-region>
          <div class="px-2">
            <select-service-inventory
              size="mini"
              v-model="filterForm.service_id"
              :id="filterForm.service_id"
            ></select-service-inventory>
          </div>
        </div>
      </div>
      <div
        class="
          col-5
          align-items-center align-self-center
          text-right
          pr-0
          d-flex
          justify-content-end
          w-reset
        "
      >
        <export-excel
          class="btn excel_btn mr-2"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Отчет по районам"
          name="Отчет по районам.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete">
            Excel
          </el-button>
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'patientResidenceReport'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>

    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th> 
          
          <crm-th
            :column="columns.city_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th><crm-th
            :column="columns.region_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.count"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <!-- <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th> -->
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>

          

          <th v-if="columns.city_id.show">
            <el-input
              size="mini"
              v-model="filterForm.city_name"
              :placeholder="columns.city_id.title"
              clearable
            >
            </el-input>
          </th>
<th v-if="columns.region_id.show">
            <el-input
              size="mini"
              v-model="filterForm.region_name"
              :placeholder="columns.region_id.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.count.show">
            <el-input
              size="mini"
              :disabled="true"
              v-model="filterForm.count"
              :placeholder="columns.count.title"
              clearable
            >
            </el-input>
          </th>
           <!-- <th v-if="columns.settings.show"></th> -->
        </tr>
      </thead>
      <transition-group name="flip-list" tag="tbody">
        <tr v-for="partnerDoctorReport in list" :key="partnerDoctorReport.id">
          <!-- {{partnerDoctorReport}} -->
          <td v-if="columns.id.show">{{ partnerDoctorReport.id }}</td>
          <td v-if="columns.city_id.show">{{ partnerDoctorReport.name }}</td>
          <td v-if="columns.region_id.show">
            {{ partnerDoctorReport.city ? partnerDoctorReport.city.name : "" }}
          </td>
          <!-- <td v-if="columns.region_id.show">{{ partnerDoctorReport.region ?  partnerDoctorReport.region[0].name : '' }}</td> -->
          <td v-if="columns.count.show">
            {{ partnerDoctorReport.patient_count }}
          </td>
           <!-- <td v-if="columns.settings.show" class="settings-td">
            <crm-setting-dropdown
              :model="partnerDoctorReport"
              name="partnerDoctorReport"
              :actions="actions"
              @edit="edit"
              @delete="destroy"
            >
            </crm-setting-dropdown>
          </td> -->
        </tr>
      </transition-group>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import lists from "@/utils/mixins/list";
import selectServiceInventory from "@/components/inventory-select/select-service-inventory";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [lists, drawer],
  components: {
    selectServiceInventory,
  },
  data() {
    return {
      drawerCreateCoculation: false,
      filterForm: {},
      drawerShow: false,
      reopenShow: false,
      reopenUpdate: false,
      excel_data: [],
      excel_fields: {},
      selectedModel: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "patientResidenceReport/list",
      columns: "patientResidenceReport/columns",
      pagination: "patientResidenceReport/pagination",
      filter: "patientResidenceReport/filter",
      sort: "patientResidenceReport/sort",
    }),
  },
  watch: {
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  mounted() {
    console.log('column', this.columns);
    this.controlExcelData();
  },
  methods: {
    ...mapActions({
      // updateList: "patientResidenceReport/patientList",
      index: "patientResidenceReport/index",
      updateSort: "patientResidenceReport/updateSort",
      updateFilter: "patientResidenceReport/updateFilter",
      updateColumn: "patientResidenceReport/updateColumn",
      updatePagination: "patientResidenceReport/updatePagination",
      empty: "patientResidenceReport/empty",
      refreshData: "patientResidenceReport/refreshData",
    }),
    async fetchData() {
      const query = {
        doctor: "patsents",
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };

      if (!this.loadingData) {
        this.loadingData = true;
        await this.index(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    async show(model) {
      this.selectedModel = model;
      this.drawer.show.status = true;
      this.$loadingCursor("wait");
      await this.showModel(model)
        .then((res) => {
          this.$loadingCursor("default");
          this.drawerShow = true;
        })
        .catch((err) => {
          this.$loadingCursor("default");
        });
    },
    drawerOpened(ref) {
      this.$refs[ref].opened();
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            switch (column.column) {
              case "region_id":
                this.excel_fields[column.title] = "city.name";
                break;
              case "city_id":
                this.excel_fields[column.title] = "name";
                break;
              case "count":
                this.excel_fields[column.title] = "patient_count";
                break;
              default:
                this.excel_fields[column.title] = column.column;
            }
          }
        }
      }
      // console.log(this.excel_fields);
    },
    emptyModel() {
      // this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
  },
};
</script>
<style lang="scss" scoped>
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
</style>



